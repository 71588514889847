@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "PPMoriMedium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "RecoletaAltBold";
  src: url('./assets/fonts/RecoletaAltBold/font.woff2') format('woff2'), url('./assets/fonts/RecoletaAltBold/font.woff') format('woff');
}
@font-face {
  font-family: "RecoletaAltLight";
  src: url('./assets/fonts/RecoletaAltLight/font.woff2') format('woff2'), url('./assets/fonts/RecoletaAltLight/font.woff') format('woff');
}
@font-face {
  font-family: "RecoletaAltSemiBold";
  src: url('./assets/fonts/RecoletaAltSemiBold/font.woff2') format('woff2'), url('./assets/fonts/RecoletaAltSemiBold/font.woff') format('woff');
}
@font-face {
  font-family: "RecoletaAltRegular";
  src: url('./assets/fonts/RecoletaAltRegular/font.woff2') format('woff2'), url('./assets/fonts/RecoletaAltRegular/font.woff') format('woff');
}
@font-face {
  font-family: "RecoletaAltBlack";
  src: url('./assets/fonts/RecoletaAltBlack/font.woff2') format('woff2'), url('./assets/fonts/RecoletaAltBlack/font.woff') format('woff');
}
@font-face {
  font-family: "RecoletaAltThin";
  src: url('./assets/fonts/RecoletaAltThin/font.woff2') format('woff2'), url('./assets/fonts/RecoletaAltThin/font.woff') format('woff');
}
@font-face {
  font-family: "RecoletaAltMedium";
  src: url('./assets/fonts/RecoletaAltMedium/font.woff2') format('woff2'), url('./assets/fonts/RecoletaAltMedium/font.woff') format('woff');
}

@font-face {
  font-family: "PPMoriMedium";
  src: url('./assets/fonts/PPMoriMedium/PPMori-Medium.woff2') format('woff2'), url('./assets/fonts/PPMoriMedium/PPMori-Medium.woff') format('woff');
}

h1,h2, .btn, .nav {
  font-family: "RecoletaAltBold";
}